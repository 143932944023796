import { getTokenFromCookie } from '@/utils/cookies';
import { Router, scrollBehavior } from '@/utils/router.js';
import { setHtmlMeta, hostBuild } from '@/utils/utils';
/**
 * query.topic、params.topic 主题帖所在版块英文短名
 */
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "core_forum" */ './home/home.vue'),
      meta: { desc: '首页' },
    },
    {
      path: '/list/:topic',
      name: 'List',
      component: () => import(/* webpackChunkName: "core_forum" */ './list/list.vue'),
      meta: { desc: '列表页' },
    },

    {
      path: '/topic/:topic',
      name: 'Topic',
      component: () => import(/* webpackChunkName: "core_forum" */ './topic/topic.vue'),
      meta: { desc: '一级板块列表页' },
    },
    {
      // params.id 主题帖ID
      path: '/detail/:id',
      name: 'Detail',
      component: () => import(/* webpackChunkName: "core_forum" */ './detail/detail.vue'),
      meta: { desc: '详情页' },
    },
    {
      // query.id：主题帖ID
      // query.comment：回复的主题帖或评论ID
      // query.reply: 评论ID
      // 仅存在 query.id 时为修改主题帖
      // 仅存在 query.reply 时为修改评论
      // 同时存在 query.id 和 query.comment 时为回复评论
      path: '/publish/:topic',
      name: 'Publish',
      component: () => import(/* webpackChunkName: "core_forum" */ './publish/publish.vue'),
      meta: { desc: '发微贴', isAuth: true },
    },
    {
      // query.id：主题帖ID
      // query.comment：回复的主题帖或评论ID
      // query.reply: 评论ID
      // 仅存在 query.id 时为修改主题帖
      // 仅存在 query.reply 时为修改评论
      // 同时存在 query.id 和 query.comment 时为回复评论
      path: '/micro/:topic',
      name: 'PublishMicro',
      component: () => import(/* webpackChunkName: "core_forum" */ './publish/publish-micro.vue'),
      meta: { desc: '发布或修改主题帖、回复主题帖或评论', isAuth: true },
    },
  
    {
      path: '/search',
      name: 'Search',
      component: () => import(/* webpackChunkName: "core_forum" */ './search/search.vue'),
      meta: { desc: '搜索结果页' },
    },
    {
      path: '/websafe',
      name: 'WebSafe',
      component: () => import(/* webpackChunkName: "core_forum" */ '@/common/websafe.vue'),
    },
    {
      path: '/404',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "core_forum" */ '@/common/404/index.vue'),
      meta: { desc: '404' },
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  // 这里只能用本地token进行判断是否登录，否则刷新页面会出现问题
  const token = getTokenFromCookie();
  if (to.meta.isAuth === true && !token) {
    location.replace(hostBuild(`/login?redirect=${location.href}`, 'account'));
    return next(false);
  }
  
  if (to.name == 'Home') {
    
    setHtmlMeta({
      title: '多伦多华人社区 - 约克论坛 - 加拿大第一中文网',
      keywords: '加拿大论坛,多伦多华人论坛,华人社区,yorkbbs forum',
      description:
        '加拿大多伦多中文论坛，提供灌水、爆料、交友、留学、移民、换汇、汽车、二手交易、旅游、宠物、房屋、商业、理财、课本交易等平台信息，其中约克论坛灌水广受华人留学生、新移民的喜爱。yorkbbs.ca',
    });
  } else if (to.name == 'Explore') {
    setHtmlMeta({
      title: '约克视频 - 多伦多华人社区 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'Search') {
    setHtmlMeta({
      title: '帖子搜索 - 多伦多华人社区 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'Publish') {
    setHtmlMeta({
      title: '发布信息 - 多伦多华人社区 - 约克论坛',
      keywords: '加拿大论坛,加拿大多伦多华人论坛,交友,时尚,汽车,二手交易,包包,留学,移民,旅游,电脑,手机,宠物,房屋,商业,理财,同乡,课本交易',
      description: '多伦多最大的中文论坛，为留学生、新移民等华人提供交友、时尚、汽车、二手交易、包包、留学、移民、旅游、电脑、手机、宠物、房屋、商业、理财、同乡、 课本交易等信息',
    });
  } else if (to.name == 'WebSafe') {
    setHtmlMeta({ title: '外链 - 约克论坛', keywords: '', description: '' });
  } else if (to.name == 'NotFound') {
    setHtmlMeta({ title: '404 - 约克论坛', keywords: '', description: '' });
  } else {
    setHtmlMeta({ title: '', keywords: '', description: '' });
  }

  if (to.name == 'NotFound' && !to.query.from) return next({ name: 'NotFound', query: { from: location.href } });
  console.log('from------->',from);
  console.log('to------->',to)

  next();
});

export { router };
