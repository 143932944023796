import Vue from 'vue';
import Vuex from 'vuex';
import { commonStore } from '@/utils/store.js';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    ...commonStore.state,
    // 我的版块列表
    _myTopics: [],
  },
  getters: {
    ...commonStore.getters,
    // 是否是管理员
    isManager(state) {
      return state._user && state._user.identity == 3;
    },
    // 是否是版主
    isGanger(state) {
      return state._user && state._user.identity == 2;
    },
    // 我的版块列表
    myTopics(state) {
      return state._myTopics;
    },
  },
  mutations: {
    ...commonStore.mutations,
    /**
     * 设置我的版块列表
     */
    setMyTopics(state, topics) {
      state._myTopics = topics;
    },
  },
  actions: {
    ...commonStore.actions,
  },
});

export { store };
